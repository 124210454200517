// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LogUtils from "./LogUtils.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";

function usePromise(fn) {
  var match = React.useState(function () {
        return {
                state: "idle",
                data: undefined,
                error: undefined
              };
      });
  var setResult = match[1];
  var run = function () {
    setResult(function (prevResult) {
          return {
                  state: "pending",
                  data: prevResult.data,
                  error: undefined
                };
        });
    Core__Promise.$$catch(fn().then(function (data) {
              setResult(function (param) {
                    return {
                            state: "fulfilled",
                            data: Caml_option.some(data),
                            error: undefined
                          };
                  });
              return Promise.resolve();
            }), (function (error) {
            setResult(function (param) {
                  return {
                          state: "rejected",
                          data: undefined,
                          error: error
                        };
                });
            return Promise.resolve();
          }));
  };
  return [
          match[0],
          run
        ];
}

function overflowingReducer(state, action) {
  var newState = state;
  var thresholds = state.thresholds;
  var currentLayoutIndex = state.currentLayoutIndex;
  var contentWidth = action.contentWidth;
  var availableWidth = action.availableWidth;
  var overflowing = contentWidth > availableWidth;
  if (overflowing) {
    var currentLayoutThreshold = thresholds[currentLayoutIndex];
    if (contentWidth !== currentLayoutThreshold) {
      thresholds.splice(currentLayoutIndex, 1, contentWidth);
      if (import.meta.env.DEV && currentLayoutIndex !== 0) {
        var widerLayoutThreshold = thresholds[currentLayoutIndex - 1 | 0];
        if (contentWidth > widerLayoutThreshold) {
          LogUtils.captureException("The threshold for layout \"" + state.layouts[currentLayoutIndex] + "\" (" + contentWidth.toString() + "px) is wider than threshold for layout \"" + state.layouts[currentLayoutIndex - 1 | 0] + "\" (" + widerLayoutThreshold.toString() + "px)\n                    \nThe layouts should be sorted from widest to narrowest (e.g. [\"XL\", \"MD\", \"SM\"]). This likely means an error in the rendering logic.");
        }
        
      }
      
    }
    var canShrink = currentLayoutIndex !== (state.layouts.length - 1 | 0);
    if (canShrink) {
      newState = {
        currentLayoutIndex: currentLayoutIndex + 1 | 0,
        layouts: state.layouts,
        thresholds: state.thresholds
      };
    }
    
  } else {
    var canGrow = currentLayoutIndex !== 0;
    if (canGrow) {
      var widerLayoutThreshold$1 = thresholds[currentLayoutIndex - 1 | 0];
      if (availableWidth > widerLayoutThreshold$1) {
        newState = {
          currentLayoutIndex: state.currentLayoutIndex - 1 | 0,
          layouts: state.layouts,
          thresholds: state.thresholds
        };
      }
      
    }
    
  }
  return newState;
}

function useIsHorizontallyOverflowing(element, layouts) {
  var match = React.useReducer(overflowingReducer, {
        currentLayoutIndex: 0,
        layouts: layouts,
        thresholds: []
      });
  var sendOverflowing = match[1];
  React.useEffect((function () {
          if (element === null || element === undefined) {
            return ;
          }
          var testOverflowing = function (param) {
            var availableWidth = element.clientWidth;
            var contentWidth = element.scrollWidth;
            console.log({
                  availableWidth: availableWidth,
                  contentWidth: contentWidth
                });
            sendOverflowing({
                  TAG: "Resized",
                  availableWidth: availableWidth,
                  contentWidth: contentWidth
                });
          };
          testOverflowing();
          var resizeObserver = new ResizeObserver(testOverflowing);
          resizeObserver.observe(element);
          return (function () {
                    resizeObserver.disconnect();
                  });
        }), [element]);
  return layouts[match[0].currentLayoutIndex];
}

export {
  usePromise ,
  overflowingReducer ,
  useIsHorizontallyOverflowing ,
}
/* react Not a pure module */
