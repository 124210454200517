// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../utils/Hooks.bs.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as DomUtils from "../../utils/DomUtils.bs.js";
import * as LogUtils from "../../utils/LogUtils.bs.js";
import * as Reactfire from "reactfire";
import * as AppStorage from "../../utils/AppStorage.bs.js";
import * as Core__Null from "@rescript/core/src/Core__Null.bs.js";
import * as Auth from "firebase/auth";
import * as BiometricAuthn from "./BiometricAuthn.bs.js";
import * as FirebaseWebAuthn from "../../backend/FirebaseWebAuthn.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as OnboardingNickName from "./OnboardingNickName.bs.js";
import * as Functions from "firebase/functions";
import * as OnboardingThrustDevice from "./OnboardingThrustDevice.bs.js";
import * as Browser from "@firebase-web-authn/browser";

function Onboarding(props) {
  var user = props.user;
  var app = Reactfire.useFirebaseApp();
  var auth = Reactfire.useAuth();
  var supportsWebAuthn = DomUtils.useSuportsPlatformWebauthn();
  var match = AppStorage.useLocalStorage(AppStorage.keyWebAuthn);
  var setWebAuthn = match[1];
  var webAuthn = match[0];
  var match$1 = AppStorage.useLocalStorage(AppStorage.keyThrustDevice);
  var setThrustDevice = match$1[1];
  var thrustDevice = match$1[0];
  var view = React.useMemo((function () {
          if (thrustDevice === undefined) {
            return "ThrustDevice";
          } else if (thrustDevice === "1" && supportsWebAuthn && webAuthn === undefined) {
            return "BiometricAuthn";
          } else if (Core__Null.mapOr(user.displayName, "", (function (prim) {
                    return prim.trim();
                  })) === "") {
            return "NickName";
          } else {
            return ;
          }
        }), [
        supportsWebAuthn,
        thrustDevice,
        user.displayName,
        webAuthn
      ]);
  var match$2 = Hooks.usePromise(async function () {
        var email = Core__Null.getExn(user.email);
        try {
          var functions = Functions.getFunctions(app, "europe-central2");
          await Browser.linkWithPasskey(auth, functions, email);
          return setWebAuthn("1");
        }
        catch (raw_error){
          var error = Caml_js_exceptions.internalToOCamlException(raw_error);
          var exn = FirebaseWebAuthn.toFirebaseWebAuthnError(error);
          if (exn.RE_EXN_ID === FirebaseWebAuthn.CancelledByUser) {
            return ;
          }
          if (exn.RE_EXN_ID === FirebaseWebAuthn.NoOperationNeeded) {
            return setWebAuthn("1");
          }
          if (exn.RE_EXN_ID === Js_exn.$$Error) {
            LogUtils.captureException(exn._1);
            throw error;
          }
          throw error;
        }
      });
  if (view === undefined) {
    return props.children;
  }
  var setupWebAuthn = match$2[0];
  switch (view) {
    case "NickName" :
        return JsxRuntime.jsx(OnboardingNickName.make, {
                    initialName: Core__Null.getOr(user.displayName, ""),
                    onSubmit: (async function (values) {
                        await Auth.updateProfile(user, {
                              displayName: values.name
                            });
                        await user.reload();
                      })
                  });
    case "ThrustDevice" :
        return JsxRuntime.jsx(OnboardingThrustDevice.make, {
                    onSkip: (function () {
                        setThrustDevice("0");
                      }),
                    onThrust: (function () {
                        setThrustDevice("1");
                      }),
                    mentionWebAuthn: supportsWebAuthn
                  });
    case "BiometricAuthn" :
        return JsxRuntime.jsx(BiometricAuthn.make, {
                    loadingOverlay: setupWebAuthn.state === "pending",
                    onSetupAuthn: match$2[1],
                    onSkip: (function () {
                        setWebAuthn("0");
                      }),
                    setupError: setupWebAuthn.error
                  });
    
  }
}

var make = Onboarding;

export {
  make ,
}
/* Hooks Not a pure module */
