// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tilia from "@tilia/react/src/Tilia.bs.js";
import * as Toast from "./Toast.bs.js";
import * as React from "react";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import ToastBannerModuleCss from "./ToastBanner.module.css";

var classes = ToastBannerModuleCss;

var buttonCloseText = "Zavřít";

var closeButtonPlaceholder = "\xA0".repeat((buttonCloseText.length << 1));

var make = React.memo(function (props) {
      var toastTree = Tilia.use(Toast.tree);
      var messages = toastTree.toasts;
      if (messages.length !== 0) {
        return JsxRuntime.jsx("div", {
                    children: messages.map(function (message) {
                          var match;
                          switch (message.TAG) {
                            case "Error" :
                                match = [
                                  "error",
                                  "alert"
                                ];
                                break;
                            case "Info" :
                                match = [
                                  "info",
                                  "status"
                                ];
                                break;
                            case "Success" :
                                match = [
                                  "success",
                                  "status"
                                ];
                                break;
                            
                          }
                          var id = message.id;
                          return React.cloneElement(JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  message.message,
                                                  closeButtonPlaceholder,
                                                  JsxRuntime.jsx("button", {
                                                        children: buttonCloseText,
                                                        className: Styles.link.base + " " + classes.buttonClose,
                                                        type: "button",
                                                        onClick: (function (param) {
                                                            Toast.removeMessage(id);
                                                          })
                                                      })
                                                ]
                                              }),
                                          role: match[1]
                                        }, id), {
                                      "data-variant": match[0]
                                    });
                        }),
                    className: classes.toastBanner
                  });
      } else {
        return null;
      }
    });

export {
  classes ,
  buttonCloseText ,
  closeButtonPlaceholder ,
  make ,
}
/* classes Not a pure module */
