// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as Rxjs from "../../Rxjs.bs.js";
import * as Rxjs$1 from "rxjs";
import * as React from "react";
import * as Header from "../../components/Header/Header.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as BeerList from "./BeerList.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateUtils from "../../utils/DateUtils.bs.js";
import * as UserRoles from "../../backend/UserRoles.bs.js";
import * as Reactfire from "reactfire";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as DrinkDialog from "./DrinkDialog.bs.js";
import * as PlaceHeader from "../../components/PlaceHeader/PlaceHeader.bs.js";
import * as RouterUtils from "../../utils/RouterUtils.bs.js";
import * as Auth from "rxfire/auth";
import * as BackendUtils from "../../utils/BackendUtils.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as PersonAddPlace from "./PersonAddPlace.bs.js";
import * as Firestore from "rxfire/firestore";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as NotificationHooks from "../../backend/NotificationHooks.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import PlaceModuleCss from "./Place.module.css";
import * as NotificationDialog from "./NotificationDialog.bs.js";

var classes = PlaceModuleCss;

function pageDataRx(auth, firestore, placeId) {
  var placeRef = Db.placeDocument(firestore, placeId);
  var placeRx = Firestore.docData(placeRef, { "idField": "uid" });
  var chargedKegsRx = Db.allChargedKegsRx(firestore, placeId);
  var placeTapsRx = Rxjs.op(Rxjs.op(placeRx, Rxjs$1.distinctUntilChanged(function (prev, curr) {
                if (prev !== undefined && curr !== undefined) {
                  return Caml_obj.equal(prev.taps, curr.taps);
                } else {
                  return false;
                }
              })), Rxjs$1.map(function (maybePlace, param) {
            return Core__Option.map(maybePlace, (function (place) {
                          return place.taps;
                        }));
          }));
  var tapsWithKegsRx = Rxjs.op(Rxjs$1.combineLatest(placeTapsRx, chargedKegsRx), Rxjs$1.map(function (data, param) {
            var placeTaps = data[0];
            if (placeTaps === undefined) {
              return {};
            }
            var chargedKegs = data[1];
            return Object.fromEntries(Core__Array.filterMap(Object.entries(placeTaps), (function (param) {
                              var tap = param[0];
                              return Core__Option.map(Core__Option.flatMap(Caml_option.null_to_opt(param[1]), (function (kegRef) {
                                                return chargedKegs.find(function (keg) {
                                                            return Db.getUid(keg) === kegRef.id;
                                                          });
                                              })), (function (keg) {
                                            return [
                                                    tap,
                                                    keg
                                                  ];
                                          }));
                            })));
          }));
  var unfinishedConsumptionsByUserRx = Rxjs.op(chargedKegsRx, Rxjs$1.map(function (chargedKegs, param) {
            var consumptionsByUser = new Map();
            chargedKegs.forEach(function (keg) {
                  Db.groupKegConsumptionsByUser(Caml_option.some(consumptionsByUser), keg);
                });
            consumptionsByUser.forEach(function (consumptions) {
                  consumptions.sort(function (a, b) {
                        return DateUtils.compare(a.createdAt, b.createdAt);
                      });
                });
            return consumptionsByUser;
          }));
  var recentlyFinishedKegsRx = Db.recentlyFinishedKegsRx(firestore, placeId);
  var recentConsumptionsByUserRx = Rxjs.op(Rxjs$1.combineLatest(unfinishedConsumptionsByUserRx, recentlyFinishedKegsRx), Rxjs$1.map(function (param, param$1) {
            var recentConsumptionsByUser = window.structuredClone(param[0]);
            param[1].forEach(function (keg) {
                  Db.groupKegConsumptionsByUser(Caml_option.some(recentConsumptionsByUser), keg);
                });
            var twelweHoursAgo = Date.now() - Db.slidingWindowInMillis;
            return new Map(Array.from(recentConsumptionsByUser.entries(), (function (param) {
                              var consumptionsInSlidingWindow = param[1].filter(function (consumption) {
                                    return consumption.createdAt.getTime() > twelweHoursAgo;
                                  });
                              consumptionsInSlidingWindow.sort(function (a, b) {
                                    return DateUtils.compare(a.createdAt, b.createdAt);
                                  });
                              return [
                                      param[0],
                                      consumptionsInSlidingWindow
                                    ];
                            })));
          }));
  var personsAllRx = Db.PersonsIndex.allEntriesSortedRx(firestore, placeId);
  var currentUserRx = Rxjs.op(Auth.user(auth), Rxjs.keepMap(function (prim) {
            if (prim === null) {
              return ;
            } else {
              return Caml_option.some(prim);
            }
          }));
  return Rxjs$1.combineLatest(placeRx, personsAllRx, tapsWithKegsRx, unfinishedConsumptionsByUserRx, recentConsumptionsByUserRx, currentUserRx);
}

function Place(props) {
  var placeId = props.placeId;
  var auth = Reactfire.useAuth();
  var firestore = Reactfire.useFirestore();
  var pageDataStatus = Reactfire.useObservable("Page_Place_" + placeId, pageDataRx(auth, firestore, placeId));
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDialog = match[1];
  var dialogState = match[0];
  var hideDialog = function (param) {
    setDialog(function (param) {
          return "Hidden";
        });
  };
  var match$1 = pageDataStatus.data;
  if (match$1 === undefined) {
    return null;
  }
  var place = match$1[0];
  if (place === undefined) {
    return "Place not found";
  }
  var currentUser = match$1[5];
  var recentConsumptionsByUser = match$1[4];
  var tapsWithKegs = match$1[2];
  var personEntries = match$1[1];
  var dispatchFreeTableNotification = NotificationHooks.useDispatchFreeTableNotification(currentUser.uid, place, recentConsumptionsByUser);
  var dispatchFreshKegNotification = NotificationHooks.useDispatchFreshKegNotification(currentUser.uid, place);
  var match$2 = Core__Option.getExn(place.accounts[currentUser.uid], undefined);
  var currentUserRole = match$2[0];
  var isUserAuthorized = function (extra) {
    return UserRoles.isAuthorized(currentUserRole, extra);
  };
  var formatConsumption = BackendUtils.getFormatConsumption(place.consumptionSymbols);
  var tmp;
  if (UserRoles.isAuthorized(currentUserRole, 50)) {
    var newrecord = Caml_obj.obj_dup(RouterUtils.createAnchorProps(undefined, "./nastaveni"));
    tmp = JsxRuntime.jsxs("a", (newrecord.className = Header.classes.buttonRight, newrecord.children = [
            JsxRuntime.jsx("span", {
                  children: "⚙️"
                }),
            JsxRuntime.jsx("span", {
                  children: "Nastavení"
                })
          ], newrecord));
  } else {
    tmp = null;
  }
  var tmp$1;
  if (typeof dialogState !== "object") {
    switch (dialogState) {
      case "Hidden" :
          tmp$1 = null;
          break;
      case "AddPerson" :
          var match$3 = Belt_Array.partition(personEntries, (function (param) {
                  return param[1].preferredTap !== undefined;
                }));
          var inactive = match$3[1];
          var existingActive = match$3[0].map(function (param) {
                return param[1].name;
              });
          var existingInactive = inactive.map(function (param) {
                return param[1].name;
              });
          tmp$1 = JsxRuntime.jsx(PersonAddPlace.make, {
                existingActive: existingActive,
                existingInactive: existingInactive,
                onDismiss: hideDialog,
                onMoveToActive: (function (personName) {
                    var match = Core__Option.getExn(inactive.find(function (param) {
                              return param[1].name === personName;
                            }), undefined);
                    var person = match[1];
                    var firstTap = Object.keys(place.taps)[0];
                    Db.PersonsIndex.update(firestore, placeId, [[
                            match[0],
                            {
                              balance: person.balance,
                              name: person.name,
                              preferredTap: firstTap,
                              recentActivityAt: person.recentActivityAt,
                              userId: person.userId
                            }
                          ]]);
                    setDialog(function (param) {
                          return "Hidden";
                        });
                  }),
                onSubmit: (function (values) {
                    Db.Person.add(firestore, placeId, values.name);
                    setDialog(function (param) {
                          return "Hidden";
                        });
                  })
              });
          break;
      case "NotificationSettings" :
          var currentUserNotificationSubscription = Core__Option.getExn(place.accounts[currentUser.uid], undefined)[1];
          tmp$1 = JsxRuntime.jsx(NotificationDialog.make, {
                currentUserNotificationSubscription: currentUserNotificationSubscription,
                currentUserUid: currentUser.uid,
                onDismiss: hideDialog,
                onUpdateSubscription: (function (newNotificationSubscription) {
                    Db.Place.updateNotificationSubscription(firestore, currentUser.uid, newNotificationSubscription, placeId);
                  }),
                place: place
              });
          break;
      
    }
  } else {
    var person = dialogState.person;
    var personId = dialogState.personId;
    var tap = person.preferredTap;
    tmp$1 = JsxRuntime.jsx(DrinkDialog.make, {
          formatConsumption: formatConsumption,
          personName: person.name,
          preferredTap: tap !== undefined ? tap : Object.keys(place.taps)[0],
          onDeleteConsumption: (function (consumption) {
              Db.Keg.deleteConsumption(firestore, placeId, consumption.kegId, consumption.consumptionId);
            }),
          onDismiss: hideDialog,
          onSubmit: (function (values) {
              var keg = tapsWithKegs[values.tap];
              var kegRef = Db.kegDoc(firestore, placeId, Db.getUid(keg));
              dispatchFreeTableNotification();
              dispatchFreshKegNotification(keg);
              Db.Keg.addConsumption(firestore, {
                    milliliters: values.consumption,
                    person: Db.placePersonDocument(firestore, placeId, personId)
                  }, kegRef.id, personId, placeId, values.tap);
              setDialog(function (param) {
                    return "Hidden";
                  });
            }),
          tapsWithKegs: tapsWithKegs,
          unfinishedConsumptions: Core__Option.getOr(match$1[3].get(personId), [])
        });
  }
  return JsxRuntime.jsx(FormattedCurrency.Provider.make, {
              value: place.currency,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(PlaceHeader.make, {
                            buttonRightSlot: JsxRuntime.jsxs("div", {
                                  children: [
                                    NotificationHooks.canSubscribe ? JsxRuntime.jsxs("button", {
                                            children: [
                                              JsxRuntime.jsx("span", {
                                                    children: "📢"
                                                  }),
                                              JsxRuntime.jsx("span", {
                                                    children: "Notifikace"
                                                  })
                                            ],
                                            className: Header.classes.buttonRight,
                                            type: "button",
                                            onClick: (function (param) {
                                                setDialog(function (param) {
                                                      return "NotificationSettings";
                                                    });
                                              })
                                          }) : null,
                                    tmp
                                  ],
                                  className: classes.toolbar
                                }),
                            createdTimestamp: place.createdAt,
                            placeName: place.name
                          }),
                      JsxRuntime.jsx("main", {
                            children: JsxRuntime.jsx(BeerList.make, {
                                  currentUserUid: currentUser.uid,
                                  formatConsumption: formatConsumption,
                                  isUserAuthorized: isUserAuthorized,
                                  onAddConsumption: (function (param) {
                                      var person = param[1];
                                      var personId = param[0];
                                      setDialog(function (param) {
                                            return {
                                                    TAG: "AddConsumption",
                                                    personId: personId,
                                                    person: person
                                                  };
                                          });
                                    }),
                                  onAddPerson: (function () {
                                      setDialog(function (param) {
                                            return "AddPerson";
                                          });
                                    }),
                                  onTogglePersonVisibility: (function (param) {
                                      var person = param[1];
                                      var preferredTap = Core__Option.isSome(person.preferredTap) ? undefined : Object.keys(place.taps)[0];
                                      Db.PersonsIndex.update(firestore, placeId, [[
                                              param[0],
                                              {
                                                balance: person.balance,
                                                name: person.name,
                                                preferredTap: preferredTap,
                                                recentActivityAt: person.recentActivityAt,
                                                userId: person.userId
                                              }
                                            ]]);
                                    }),
                                  personEntries: personEntries,
                                  recentConsumptionsByUser: recentConsumptionsByUser
                                })
                          }),
                      tmp$1
                    ],
                    className: Styles.page.narrow + " " + classes.root
                  })
            });
}

var make = Place;

export {
  classes ,
  pageDataRx ,
  make ,
}
/* classes Not a pure module */
