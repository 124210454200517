// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../utils/Hooks.bs.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import HeaderModuleCss from "./Header.module.css";

var classes = HeaderModuleCss;

function Header(props) {
  var headerRef = React.useRef(null);
  var layout = Hooks.useIsHorizontallyOverflowing(headerRef.current, [
        "xl",
        "sm"
      ]);
  return React.cloneElement(JsxRuntime.jsxs("header", {
                  children: [
                    JsxRuntime.jsx("h2", {
                          children: props.headingSlot
                        }),
                    JsxRuntime.jsx("p", {
                          children: props.subheadingSlot
                        }),
                    props.buttonLeftSlot,
                    props.buttonRightSlot
                  ],
                  ref: Caml_option.some(headerRef),
                  className: classes.root + " " + Core__Option.getOr(props.className, "")
                }), {
              "data-layout": layout
            });
}

var make = Header;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
