// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as Rxjs from "../../Rxjs.bs.js";
import * as Rxjs$1 from "rxjs";
import * as React from "react";
import * as Firebase from "../../backend/Firebase.bs.js";
import * as LogUtils from "../../utils/LogUtils.bs.js";
import * as Reactfire from "../../backend/Reactfire.bs.js";
import * as Reactfire$1 from "reactfire";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Auth from "rxfire/auth";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Firestore from "rxfire/firestore";
import * as NotificationHooks from "../../backend/NotificationHooks.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function isSubscribedToNotificationsRx(auth, firestore, placeId) {
  var currentUserRx = Rxjs.op(Auth.user(auth), Rxjs.keepMap(function (prim) {
            if (prim === null) {
              return ;
            } else {
              return Caml_option.some(prim);
            }
          }));
  var placeRef = Db.placeDocument(firestore, placeId);
  var placeRx = Firestore.docData(placeRef, { "idField": "uid" });
  return Rxjs.op(Rxjs$1.combineLatest(currentUserRx, placeRx), Rxjs$1.map(function (param, param$1) {
                  var place = param[1];
                  if (place !== undefined) {
                    return Core__Option.getOr(Core__Option.map(place.accounts[param[0].uid], (function (param) {
                                      return param[1] > 0;
                                    })), false);
                  } else {
                    return false;
                  }
                }));
}

var make = React.memo(function (props) {
      if (import.meta.env.PROD && NotificationHooks.canSubscribe) {
        var auth = Reactfire$1.useAuth();
        var firestore = Reactfire$1.useFirestore();
        var messaging = Reactfire.useMessaging();
        var updateNotificationToken = NotificationHooks.useUpdateNotificationToken();
        var isSubscribedToNotifications = Reactfire$1.useObservable("isSubscribedToNotifications", isSubscribedToNotificationsRx(auth, firestore, props.placeId));
        React.useEffect((function () {
                var match = isSubscribedToNotifications.data;
                if (match !== undefined && match) {
                  Core__Promise.$$catch(Firebase.Messaging.getToken(messaging).then(updateNotificationToken).then(function (param) {
                            return Promise.resolve();
                          }), (function (error) {
                          var exn = Core__Option.getExn(Caml_js_exceptions.as_js_exn(error), undefined);
                          LogUtils.captureException(exn);
                          return Promise.resolve();
                        }));
                }
                
              }), [isSubscribedToNotifications.data]);
      }
      return null;
    });

export {
  isSubscribedToNotificationsRx ,
  make ,
}
/* make Not a pure module */
