// Generated by ReScript, PLEASE EDIT WITH CARE


function bitAnd(a, b) {
  return (a & b);
}

function bitOr(a, b) {
  return (a | b);
}

function bitNot(a) {
  return (~a);
}

export {
  bitAnd ,
  bitOr ,
  bitNot ,
}
/* No side effect */
