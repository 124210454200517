// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.bs.js";
import * as Toast from "./components/Toast/Toast.bs.js";
import * as React from "react";
import * as Styles from "./styles/components/Styles.bs.js";
import * as LogUtils from "./utils/LogUtils.bs.js";
import * as Reactfire from "./backend/Reactfire.bs.js";
import * as Reactfire$1 from "reactfire";
import * as AppStorage from "./utils/AppStorage.bs.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Client from "react-dom/client";
import * as JsxRuntime from "react/jsx-runtime";
import * as VirtualpwaRegister from "virtual:pwa-register";

LogUtils.initSentry();

var updateSWRef = {
  contents: (function (prim) {
      
    })
};

updateSWRef.contents = VirtualpwaRegister.registerSW({
      onNeedRefresh: (function () {
          Toast.addMessage({
                TAG: "Info",
                id: "sw-need-refresh",
                message: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        "Je k dispozici nová verze\xA0\xA0",
                        JsxRuntime.jsx("button", {
                              children: "Aktualizovat",
                              className: Styles.link.base,
                              type: "button",
                              onClick: (function (param) {
                                  updateSWRef.contents();
                                })
                            })
                      ]
                    })
              });
        }),
      onOfflineReady: (function () {
          if (!AppStorage.hasSeenOfflineModeReady()) {
            return Toast.addMessage({
                        TAG: "Info",
                        id: "sw-offline-ready",
                        message: "Po přihlášení se stránka obejde i bez internetu.",
                        onClose: (function () {
                            AppStorage.markSeenOfflineModeReady();
                          })
                      });
          }
          
        })
    });

Client.createRoot(Core__Option.getExn(Caml_option.nullable_to_opt(document.querySelector("#root")), undefined)).render(JsxRuntime.jsx(React.StrictMode, {
          children: JsxRuntime.jsx(Reactfire$1.FirebaseAppProvider, {
                firebaseConfig: Caml_option.some(Reactfire.firebaseConfig),
                children: JsxRuntime.jsx(ReactIntl.IntlProvider, {
                      locale: "cs",
                      onError: (function (err) {
                          ((process.env.NODE_ENV === 'development' && err.code !== "MISSING_TRANSLATION" && console.error(err)));
                        }),
                      children: JsxRuntime.jsx(App.make, {})
                    }),
                suspense: true
              })
        }));

export {
  updateSWRef ,
}
/*  Not a pure module */
