// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dialog from "../../components/Dialog/Dialog.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as InputToggle from "../../components/InputToggle/InputToggle.bs.js";
import * as BitwiseUtils from "../../utils/BitwiseUtils.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as NotificationHooks from "../../backend/NotificationHooks.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import NotificationDialogModuleCss from "./NotificationDialog.module.css";

var classes = NotificationDialogModuleCss;

function NotificationDialog(props) {
  var onUpdateSubscription = props.onUpdateSubscription;
  var onDismiss = props.onDismiss;
  var currentUserNotificationSubscription = props.currentUserNotificationSubscription;
  var sendTestNotification = NotificationHooks.useDispatchTestNotification(props.currentUserUid, props.place);
  var permission = window.Notification.permission;
  return JsxRuntime.jsxs(Dialog.make, {
              children: [
                JsxRuntime.jsxs("header", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "Nastavení notifikací"
                            }),
                        JsxRuntime.jsx("p", {
                              children: "Přihlaš se k odběru důležitých zpráv."
                            })
                      ]
                    }),
                permission === "denied" ? JsxRuntime.jsx("p", {
                        children: "Notifikace pro tuto aplikaci jsou zakázané, musíš je povolit v nastavení prohlížeče nebo systému.",
                        className: Styles.messageBar.variantDanger
                      }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          permission === "granted" ? null : JsxRuntime.jsx("p", {
                                  children: "Jakmile zapneš notifikace, bude to po tobě chtít ještě odsouhlasit povolení pro tuto aplikaci. Bez povolení notifikace nefungují.",
                                  className: Styles.messageBar.base
                                }),
                          JsxRuntime.jsx("form", {
                                children: JsxRuntime.jsxs("fieldset", {
                                      children: [
                                        JsxRuntime.jsx(InputWrapper.make, {
                                              inputName: "free_table",
                                              inputSlot: JsxRuntime.jsx(InputToggle.make, {
                                                    checked: BitwiseUtils.bitAnd(currentUserNotificationSubscription, 1) !== 0,
                                                    onChange: (function ($$event) {
                                                        var target = $$event.target;
                                                        var checked = target.checked;
                                                        onUpdateSubscription(checked ? BitwiseUtils.bitOr(currentUserNotificationSubscription, 1) : BitwiseUtils.bitAnd(currentUserNotificationSubscription, BitwiseUtils.bitNot(1)));
                                                      })
                                                  }),
                                              labelSlot: "První pivo"
                                            }),
                                        JsxRuntime.jsx(InputWrapper.make, {
                                              inputName: "fresh_keg",
                                              inputSlot: JsxRuntime.jsx(InputToggle.make, {
                                                    checked: BitwiseUtils.bitAnd(currentUserNotificationSubscription, 2) !== 0,
                                                    onChange: (function ($$event) {
                                                        var target = $$event.target;
                                                        var checked = target.checked;
                                                        onUpdateSubscription(checked ? BitwiseUtils.bitOr(currentUserNotificationSubscription, 2) : BitwiseUtils.bitAnd(currentUserNotificationSubscription, BitwiseUtils.bitNot(2)));
                                                      })
                                                  }),
                                              labelSlot: "Čerstvý sud"
                                            })
                                      ],
                                      className: "reset " + Styles.fieldset.grid
                                    })
                              })
                        ]
                      }),
                import.meta.env.PROD ? null : JsxRuntime.jsx("button", {
                        children: "Send test notification",
                        className: Styles.button.base,
                        type: "button",
                        onClick: (function (param) {
                            sendTestNotification();
                          })
                      }),
                JsxRuntime.jsx("footer", {
                      children: JsxRuntime.jsx("button", {
                            children: "Zavřít",
                            className: Styles.button.base,
                            type: "button",
                            onClick: (function (param) {
                                onDismiss();
                              })
                          })
                    })
              ],
              className: classes.notificationDialog,
              visible: true
            });
}

var make = NotificationDialog;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
