// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rxjs from "rxjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

function $$void(param) {
  
}

function make(next, error, complete, param) {
  var tmp = {};
  if (next !== undefined) {
    tmp.next = Caml_option.valFromOption(next);
  }
  if (error !== undefined) {
    tmp.error = Caml_option.valFromOption(error);
  }
  if (complete !== undefined) {
    tmp.complete = Caml_option.valFromOption(complete);
  }
  return tmp;
}

var Observer = {
  make: make
};

var Subscriber = {};

var Observable = {};

var Subject = {};

var BehaviorSubject = {};

var ReplaySubject = {};

function $$const(b) {
  return Rxjs.map(function (param, param$1) {
              return b;
            });
}

function op(xs, operator) {
  return operator(xs);
}

function keepSome(xs) {
  return Rxjs.map(function (a, param) {
                return Core__Option.getExn(a, undefined);
              })(Rxjs.filter(Core__Option.isSome)(xs));
}

function keepMap(f) {
  return function (xs) {
    return keepSome(Rxjs.map(function (a, param) {
                      return f(a);
                    })(xs));
  };
}

function distinctBy(fn) {
  return function (source) {
    return new Rxjs.Observable((function (subscriber) {
                  var last = {
                    contents: undefined
                  };
                  var sub = source.subscribe(make((function (x) {
                              var fnx = fn(x);
                              if (Caml_obj.notequal(Caml_option.some(fnx), last.contents)) {
                                last.contents = Caml_option.some(fnx);
                                subscriber.next(x);
                                return ;
                              }
                              
                            }), (function (e) {
                              subscriber.complete(e);
                            }), (function () {
                              subscriber.complete();
                            }), undefined));
                  return (function () {
                            sub.unsubscribe();
                          });
                }));
  };
}

export {
  $$void ,
  Observer ,
  Subscriber ,
  Observable ,
  Subject ,
  BehaviorSubject ,
  ReplaySubject ,
  $$const ,
  op ,
  keepSome ,
  keepMap ,
  distinctBy ,
}
/* rxjs Not a pure module */
